/* For use in src/lib/core/theming/_palette.scss */
$md-deltablue: (
    50: #ebf4fa,
    100: #cce3f2,
    200: #abd0e9,
    300: #89bde0,
    400: #6faeda,
    500: #56a0d3,
    600: #4f98ce,
    700: #458ec8,
    800: #3c84c2,
    900: #2b73b7,
    A100: #f9fcff,
    A200: #c6e2ff,
    A400: #93c8ff,
    A700: #7abbff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
