.dashboard {
    display: grid;
    gap: 0;
    min-height: calc(100vh - 22rem);

    @include desktop-small {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    .sidebar {
        background: #fff;

        @include desktop-small {
            grid-column: 1 / span 4;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border-right: 1px solid rgba(black, 0.06);
        }

        @include desktop-medium {
            grid-column: 1 / span 3;
        }

        @include desktop-x-large {
            grid-column: 1 / span 2;
        }

        &-header {
            padding: 2rem 2rem 1rem 2rem;

            @include desktop-small {
                padding: 4rem 2rem 1rem 2rem;
            }
        }

        &-title {
            font-size: $type-size-100;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            font-weight: 400;
        }

        &-nav {
            padding: 0;
            margin: 0;
            list-style-type: none;
        }

        &-item {
            border-bottom: 1px solid rgba(black, 0.06);

            &:first-of-type {
                border-top: 1px solid rgba(black, 0.06);
            }
        }

        &-link {
            font-size: $type-size-200;
            padding: 1rem 2rem;
            display: block;
            transition: all 200ms ease;
            color: #2a2a2a;
            position: relative;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                content: "";
                width: 0.25rem;
                height: 100%;
                transition: all 200ms ease;
            }

            .fa {
                display: inline-block;
                margin-right: 0.5rem;
                font-size: $type-size-300;
                transition: all 200ms ease;
                background: transparent;
            }

            &.active {
                color: $primary;
                background: rgba($primary, 0.05);
            }

            &:hover {
                text-decoration: none;
                color: $primary;
                background: rgba($primary, 0.05);
            }
        }
    }

    .main {
        @include desktop-small {
            padding-left: 0;
            grid-column: 5 / span 8;
        }

        @include desktop-medium {
            grid-column: 4 / span 9;
        }

        @include desktop-x-large {
            grid-column: 3 / span 10;
        }
    }
}
