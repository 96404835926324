h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

.page-title {
    font-weight: 400;
    font-size: 2em;
    color: $base-text;
}

.section-title {
    font-weight: 400;
    font-size: 1.5em;
    padding-bottom: 0.5rem;
    color: $base-text;
}
