.kv-pair {
    /* Div wrapper for the key-value pair */
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    column-gap: 10px;
}

.key {
    /* key/label styling */
    font-weight: 500;
    font-size: 18px;
    color: $base-text;
    width: 200px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
}

.value {
    /* input styling */
    width: 100%;
    font-size: 16px;
    line-height: 1.25;
    flex-grow: 1;
}

esa-value-display {
    /* input styling */
    width: 100%;
    font-size: 16px;
    flex-grow: 2;
    line-height: 1.25;
}

.filter {
    /* Select w/ over 7 options auto sets a filter styling */
    padding: 0.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    z-index: 1;
    top: 0px;
    position: sticky;
    background-color: white;
    align-items: center;
}

/* required marker styling */
.required::after {
    content: "*";
}

.required {
    display: inline;
    color: #d50000;
    margin-right: 0.5rem;
}
