@import "node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

$grid-link: #fdaf2b;
$border-gray: #bdc3c7;
$grid-header-background: #f08b1d;

.ag-theme-alpine {
    @include ag-theme-alpine(
        (
            // use theme parameters where possible
            borders: true,
            header-column-separator: true,
            header-column-separator-height: 50%
        )
    );

    .ag-header {
        background-color: $primary;
        text-align: center;
    }

    .ag-header-row {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }

    .ag-header-icon {
        color: #fff;
    }

    .ag-popup {
        position: initial;
    }

    .ag-menu {
        background: white;
        width: 300px;
    }

    .ag-floating-filter-button-button {
        color: $grid-link;
    }

    .ag-floating-filter-body {
        color: #535353;
    }
    
}

// AG Grids filters
.ag-filter {
    background-color: #f1f1f1;
}

.ag-filter .ag-filter-body .ag-wrapper input {
    margin-top: 4px;
    padding: 4px;
}

.ag-header-row-column-filter {
    background-color: #f1f1f1;
}

.ag-theme-material {
    --ag-header-cell-hover-background-color: $grid-header-background;
    --ag-header-cell-moving-background-color: $grid-header-background;
}

.ag-root {
    font-size: $type-size-200;
    color: #535353;
}

.context-menu {
    display: none;
    position: absolute;
    top: 2rem;
    left: 0.25rem;
    background: white;
    padding: 0.75rem;
    box-shadow: $shadow-100;
    border-radius: 0.25rem;
    border: 1px solid rgba($primary, 1);

    &.active {
        display: grid;
        min-width: 8rem;
    }

    button,
    a {
        font-size: $type-size-150;
        display: inline-block;
        padding: 0;
        border: none;
        text-align: left;
        color: $primary;
        background: none;
        appearance: none;
        line-height: 1.5em !important;

        & + * {
            margin-top: 0.5rem;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &-btn {
        font-size: $type-size-100;
        font-weight: bold;
        line-height: auto;
    }
}
