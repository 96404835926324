$tablePageHeights: calc(100vh - 25rem);

.table-max-height {
    max-height: 500px;
}

.table-responsive {
    height: $tablePageHeights;
}

.table-responsive-500 {
    height: 500px;
}

@import "ag-grid";
