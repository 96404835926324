$viewHeight: calc(100vh - 10rem);

body {
    background: linear-gradient(180deg, rgba(218, 218, 218, 0.05) 0%, rgba(121, 120, 120, 0.05) 58.85%), #f1f1f1;
}

// .site {
//     min-height: calc($viewHeight - 12rem);
// }

.site-wrapper {
    width: calc(100% - 4rem);
    max-width: 1920px;
    margin: 1rem auto;
    border-radius: 8px;
    box-shadow: 0 0 1.5rem rgba(black, 0.07);
    background: rgba(white, 0.9);
}

.list-view,
.detail-view {
    min-height: calc($viewHeight - 15rem);
    padding: 2rem;
}

.actions-bar {
    display: flex;
    justify-content: flex-end;
}

.grid-12 {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

@for $i from 1 through 12 {
    .g-col-#{$i} {
        grid-column: span 12;
        @include desktop-small {
            grid-column: span $i;
        }
    }
}

.flex {
    &-start {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-end {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}

.page-header {
    margin-bottom: 1rem;
}

.main {
    min-height: calc(100vh - 22rem);
}
