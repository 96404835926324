$theme-colors: (
    "primary": #f08b1d,
    "secondary": #c4c4c4,
    "info": #56a0d3,
    "light": #f5f5f5,
    "muted": #dee2e6,
    "danger": #ff0000,
    "success": #5e9732,
    "warning": #ffff9f,
    "h4-styled": #194349,
    "footer": #999999,
    "header-background": #343b85,
    "select": #1f4d3d,
    "header-nav-link": #fff,
    "grid-header-background": #343b85,
    "grid-accent-color": #56a0d3,
    "grid-hover-color": cornsilk,
    "account-dropdown-text": #fff,
    "header-gradient-start": transparent,
    "header-gradient-end": transparent,
);

// this looks redundant, but we need to override the bootstrap variables in both ways.
//Custom variables only need the one override

$primary: #f08b1d; // ESA Orange
$secondary: #c4c4c4;
$info: #56a0d3; // ESA Blue Secondary
$light: #f5f5f5;
$muted: #dee2e6;
$danger: #ff0000;
$success: #00cc00;
$warning: #ffff9f;

$account-dropdown-text: #fff;
$button-border-and-background: #56a0d3; // ESA Blue Secondary
$button-hover-text-color: #ffffff;
$button-text-color: #fff;
$button-hover-background: #fff;
$button-toggle-hover: #2b73b7;
$footer: #999999;
$header-background: #f0644b;
$header-gradient-start: transparent;
$header-gradient-end: transparent;

$header-nav-link: #fff;
$header-nav-link-hover: #5e9732; // ESA Supplementary Green
$h4-styled: #194349;
$select: #1f4d3d;
$select-and-nav-dropdown-hover: #87a1cc;
$select-and-nav-hover: #87a1cc;
$text-muted: darken(#d3d3d3, 15%);
$text-warning: darken($warning, 20%);

$base-text: #333; // PTO text color

$card-cap-bg: #dfdfdf;

$modal-header-background: #dfdfdf;
$link-color: #205c90;

$white: #fff;
$site-link: #5e9732; // ESA Supplementary Green
$site-link-hover: #417e13;
